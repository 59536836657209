import React from 'react';

const colors = [
    'rgba(71, 136, 226, 0.9)', // blue col
    'rgba(224, 75, 94, 0.9)',  // red col
    'rgba(240, 159, 54, 0.9)'  // orange col
  ];
  
  const AnimatedBackground = () => {
    return (
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute inset-0 opacity-50">
          <div className="particles">
            {[...Array(20)].map((_, i) => (
              <div
                key={i}
                className="particle"
                style={{
                  '--x': `${Math.random() * 100}%`,
                  '--y': `${Math.random() * 100}%`,
                  '--duration': `${10 + Math.random() * 20}s`,
                  '--delay': `${-Math.random() * 20}s`,
                  '--size': `${4 + Math.random() * 6}px`,
                  '--opacity': Math.random() * 0.7 + 0.3,
                  '--color': colors[Math.floor(Math.random() * colors.length)]
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

export default AnimatedBackground;
