import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AnimatedBackground from "./components/AnimatedBackground";
import {
  Activity,
  Eye,
  Cloud,
  Brain,
  ChevronRight,
  Users,
  BarChart,
  Globe,
  Mail,
  MessageCircle,
  Github,
  Stethoscope,
  DollarSign,
  ShoppingCart,
  Leaf,
  ArrowUp,
  User,
  Phone,
  MessageSquare,
  Send,
} from "lucide-react";

const LandingPage = () => {
  const [activeSection, setActiveSection] = useState("");
  const [showContactForm, setShowContactForm] = useState(false);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleContactSubmit = (e) => {
    e.preventDefault();
    setShowContactForm(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      
      const sections = ["services", "about", "industries", "contact"];
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          // Adjusted threshold for better detection of contact section
          return rect.top <= 150 && rect.bottom >= 0;
        }
        return false;
      });
      setActiveSection(currentSection || "");
      setShowScrollUp(position > 200);
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 150; // Height of fixed header plus some padding
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
     }
    };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#6ec7e9] to-[#4788e2] text-gray-800">
      {/* Navigation */}
      <motion.nav
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        className="glass fixed top-0 left-0 right-0 z-10"
        style={{
          background: scrollPosition < 100 
          ? 'rgba(255, 255, 255, 0.9)' 
          : 'rgba(71, 136, 226, 0.2)',
          backdropFilter: 'blur(8px)',
          transition: 'background-color 0.3s ease-in-out'
        }}
      >
        <div className="max-w-6xl mx-auto px-4 py-3 flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="flex items-center"
          >
            <img src="/qq_logo.png" alt="QuanQuest.ai" className="h-16 mr-4" />
          </motion.div>
          <div className="hidden md:flex space-x-6">
            {["services", "about", "industries", "contact"].map((item) => (
              <motion.a
                key={item}
                href={`#${item}`}
                onClick={() => scrollToSection(item)}
                className={`hover:text-[#e04b5e] transition-colors capitalize ${
                  activeSection === item 
                    ? 'text-[#e04b5e]' 
                    : scrollPosition < 100 
                      ? 'text-[#4788e2]' 
                      : 'text-white'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {item}
              </motion.a>
            ))}
          </div>
        </div>
      </motion.nav>

      {/* Hero Section */}
      <header className="relative bg-[#4788e2] text-white py-32 px-4 overflow-hidden">
        <AnimatedBackground />
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="md:w-1/2 mb-10 md:mb-0"
          >
            <h1 className="text-5xl font-bold mb-6 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100">
              Empowering Intelligence, Shaping the Future with AI
            </h1>
            <p className="text-xl mb-10 opacity-90">
              Leverage the latest innovations in Computer Vision, LLM, NLP,
              Remote Sensing, and Machine Learning for transformative business
              solutions.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="glass px-8 py-3 rounded-full font-semibold hover:bg-white hover:text-[#4788e2] transition-all duration-300 shadow-lg"
              onClick={() => scrollToSection("contact")}
            >
              Get Started
            </motion.button>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="md:w-1/2"
          >
            <img
              src="/contact_backround.png"
              alt="AI Illustration"
              className="w-full max-w-sm mx-auto filter drop-shadow-2xl"
            />
          </motion.div>
        </div>
      </header>

      {/* Our Services */}
      <section id="services" className="pt-32 py-20 px-4 bg-white">
        <div className="max-w-6xl mx-auto">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-center mb-16 text-[#e04b5e]"
          >
            Our Services
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            {[
              {
                icon: <Eye className="w-12 h-12 text-[#4788e2]" />,
                title: "Computer Vision Solutions",
                description:
                  "Enable real-time object detection, facial recognition, and automated image analysis.",
              },
              {
                icon: <Activity className="w-12 h-12 text-[#4788e2]" />,
                title: "LLM and NLP Services",
                description:
                  "Conversational AI, text generation, and automated document processing.",
              },
              {
                icon: <Cloud className="w-12 h-12 text-[#4788e2]" />,
                title: "Remote Sensing Technology",
                description:
                  "Utilize satellite or aerial imagery to monitor environmental changes and infrastructure analysis.",
              },
              {
                icon: <Brain className="w-12 h-12 text-[#4788e2]" />,
                title: "Machine Learning Customization",
                description:
                  "Tailored ML solutions to solve business challenges like prediction, automation, or optimization.",
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <ServiceCard {...service} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Why QuanQuest.ai */}
      <section id="about" className="pt-32 py-20 px-4 bg-[#f8f9fa]">
        <div className="max-w-6xl mx-auto">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-center mb-16 text-[#e04b5e]"
          >
            Why QuanQuest Labs?
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {[
              {
                icon: <Users className="w-12 h-12 text-[#4788e2]" />,
                title: "Expertise & Innovation",
                description:
                  "Our team's deep knowledge and cutting-edge research set us apart in the AI landscape.",
              },
              {
                icon: <BarChart className="w-12 h-12 text-[#4788e2]" />,
                title: "Custom AI Solutions",
                description:
                  "We tailor AI to fit different industries, from healthcare to fintech to agriculture.",
              },
              {
                icon: <Globe className="w-12 h-12 text-[#4788e2]" />,
                title: "Latest Technologies",
                description:
                  "We implement the most recent AI innovations and frameworks to deliver superior results.",
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <FeatureCard {...feature} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Industries We Serve */}
      <section id="industries" className="pt-32 py-20 px-4 bg-white">
        <div className="max-w-6xl mx-auto">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-center mb-16 text-[#e04b5e]"
          >
            Industries We Serve
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: <Stethoscope />,
                title: "Healthcare",
                description:
                  "AI for diagnostics, drug discovery, and medical imaging",
              },
              {
                icon: <DollarSign />,
                title: "Finance",
                description:
                  "Automated risk assessment, fraud detection, and financial forecasting",
              },
              {
                icon: <ShoppingCart />,
                title: "Retail",
                description:
                  "Personalized marketing, inventory management, and predictive analytics",
              },
              {
                icon: <Leaf />,
                title: "Agriculture",
                description:
                  "Remote sensing for crop health monitoring and yield prediction",
              },
            ].map((industry, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <IndustryCard {...industry} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section id="contact" className="pt-32 bg-[#f8f9fa] py-20 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-6 text-[#e04b5e]"
          >
            Ready to Transform Your Business with AI?
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-xl mb-10 text-gray-600"
          >
            Let's discuss how QuanQuest Labs can bring cutting-edge AI solutions
            to your industry.
          </motion.p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowContactForm(true)}
            className="bg-[#4788e2] text-white px-10 py-4 rounded-full font-semibold text-lg hover:bg-[#f09f36] transition-colors flex items-center mx-auto mb-8"
          >
            Contact Us Today
            <ChevronRight className="ml-2" />
          </motion.button>
          {/* Newsletter Subscription */}
          <div className="max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Stay Updated with Our AI Innovations</h3>
            <form className="flex gap-2">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-grow p-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#4788e2]"
                required
              />
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                className="bg-[#4788e2] text-white px-4 py-2 rounded-lg font-medium hover:bg-[#f09f36] transition-all duration-300"
                >
                Subscribe
              </motion.button>
            </form>
          </div>
        </div>

        <AnimatePresence>
          {showContactForm && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="p-10 rounded-2xl max-w-md w-full mx-4"
                style={{
                  background: 'rgba(255, 255, 255, 0.95)',
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                  backdropFilter: 'blur(20px)',
                  border: '1px solid rgba(255, 255, 255, 0.3)'
                }}
              >
                <h3 className="text-3xl font-bold mb-8 text-[#4788e2] flex items-center">
                  <Mail className="w-7 h-7 mr-3" />
                  Let's Connect
                </h3>
                <form onSubmit={handleContactSubmit} className="space-y-6">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="w-full p-4 bg-gray-50 border border-gray-200 rounded-xl pl-12 
                      focus:outline-none focus:ring-2 focus:ring-[#4788e2] text-gray-700 placeholder-gray-400
                      transition-all duration-300"
                      required
                    />
                    <User className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 transform -translate-y-1/2" />
                  </div>
                  <div className="relative">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="w-full p-4 bg-gray-50 border border-gray-200 rounded-xl pl-12 
                      focus:outline-none focus:ring-2 focus:ring-[#4788e2] text-gray-700 placeholder-gray-400
                      transition-all duration-300"
                      required
                    />
                    <Mail className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 transform -translate-y-1/2" />
                  </div>
                  <div className="relative">
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      className="w-full p-4 bg-gray-50 border border-gray-200 rounded-xl pl-12 
                      focus:outline-none focus:ring-2 focus:ring-[#4788e2] text-gray-700 placeholder-gray-400
                      transition-all duration-300"
                      required
                    />
                    <Phone className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 transform -translate-y-1/2" />
                  </div>
                  <div className="relative">
                    <textarea
                      placeholder="Your Message"
                      className="w-full p-4 bg-gray-50 border border-gray-200 rounded-xl pl-12 h-36 resize-none
                      focus:outline-none focus:ring-2 focus:ring-[#4788e2] text-gray-700 placeholder-gray-400
                      transition-all duration-300"
                      required
                    ></textarea>
                    <MessageSquare className="w-5 h-5 text-gray-400 absolute left-4 top-6" />
                  </div>
                  <div className="flex justify-end space-x-4 pt-4">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      type="button"
                      onClick={() => setShowContactForm(false)}
                      className="px-6 py-3 text-gray-600 hover:text-[#4788e2] transition-colors rounded-xl"
                    >
                      Cancel
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      type="submit"
                      className="bg-[#4788e2] text-white px-8 py-3 rounded-xl
                      hover:bg-[#3a6dbd] transition-all duration-300 flex items-center font-semibold"
                    >
                      <Send className="w-4 h-4 mr-2" />
                      Submit
                    </motion.button>
                  </div>
                </form>

              </motion.div>

            </motion.div>
          )}
        </AnimatePresence>
      </section>

      {/* Footer */}
      <footer className="bg-[#4788e2] text-white py-8 px-4">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          
          <div className="flex-1 flex justify-center items-center gap-8 my-4 md:my-0">
            <span className="font-medium">Contact Us:</span>
            {[
              { icon: <Github className="w-5 h-5" />, href: "https://github.com/quanquest" },
              { icon: <MessageCircle className="w-5 h-5" />, href: "https://linkedin.com/company/quanquest" },
              { icon: <Mail className="w-5 h-5" />, href: "mailto:contact@quanquest.labs@gmail.com" }
            ].map((item, index) => (
              <motion.a
                key={index}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -2 }}
                className="hover:text-[#f09f36] transition-colors"
              >
                {item.icon}
              </motion.a>
            ))}
          </div>

          <span className="text-sm opacity-90">© 2024 QuanQuest.ai</span>
        </div>
      </footer>
      <AnimatePresence>
        {showScrollUp && (
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 glass text-white p-3 rounded-full shadow-lg hover:bg-white hover:text-[#4788e2] transition-all duration-300 z-50"
            aria-label="Scroll to top"
          >
            <ArrowUp className="w-6 h-6" />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

const ServiceCard = ({ icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0.9 }}
    whileHover={{ 
      scale: 1.02,
      boxShadow: "0 20px 40px rgba(71, 136, 226, 0.15)"
    }}
     className="h-[400px] p-8 rounded-2xl bg-white flex flex-col
    transition-all duration-300 border border-gray-100"
    >
    <motion.div 
      className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#4788e2]/10 to-transparent 
      rounded-full -mr-16 -mt-16 z-0"
    />
    
    <div className="relative z-10">
      <motion.div 
        whileHover={{ y: -5 }}
        className="inline-block bg-gradient-to-r from-[#4788e2] to-[#6ec7e9] p-3 rounded-xl
        shadow-lg mb-6"
      >
        {React.cloneElement(icon, { className: "w-8 h-8 text-white" })}
      </motion.div>
      
      <h3 className="text-xl font-bold text-gray-800 mb-4 tracking-wide">
        {title}
      </h3>
      
      <p className="text-gray-600 leading-relaxed">
        {description}
      </p>
    </div>
    
    <motion.div 
      className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-tr from-[#e04b5e]/10 to-transparent 
      rounded-full -ml-12 -mb-12 z-0"
    />
  </motion.div>
);


const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0.9 }}
    whileHover={{ 
      scale: 1.02,
      boxShadow: "0 20px 40px rgba(71, 136, 226, 0.15)"
    }}
     className="h-[300px] p-8 rounded-2xl bg-white flex flex-col
    transition-all duration-300 border border-gray-100"
    >
    <motion.div 
      className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#4788e2]/10 to-transparent 
      rounded-full -mr-16 -mt-16 z-0"
    />
    
    <div className="relative z-10">
      <motion.div 
        whileHover={{ y: -5 }}
        className="inline-block bg-gradient-to-r from-[#4788e2] to-[#6ec7e9] p-3 rounded-xl
        shadow-lg mb-6"
      >
        {React.cloneElement(icon, { className: "w-8 h-8 text-white" })}
      </motion.div>
      
      <h3 className="text-xl font-bold text-gray-800 mb-4 tracking-wide">
        {title}
      </h3>
      
      <p className="text-gray-600 leading-relaxed">
        {description}
      </p>
    </div>
    
    <motion.div 
      className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-tr from-[#e04b5e]/10 to-transparent 
      rounded-full -ml-12 -mb-12 z-0"
    />
  </motion.div>
);

const IndustryCard = ({ title, description, icon }) => (
  <motion.div
    initial={{ opacity: 0.9 }}
    whileHover={{ 
      scale: 1.02,
      boxShadow: "0 20px 40px rgba(71, 136, 226, 0.15)"
    }}
     className="h-[280px] p-8 rounded-2xl bg-white flex flex-col
    transition-all duration-300 border border-gray-100"
    >
    <motion.div 
      className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#4788e2]/10 to-transparent 
      rounded-full -mr-16 -mt-16 z-0"
    />
    
    <div className="relative z-10">
      <motion.div 
        whileHover={{ y: -5 }}
        className="inline-block bg-gradient-to-r from-[#4788e2] to-[#6ec7e9] p-3 rounded-xl
        shadow-lg mb-6"
      >
        {React.cloneElement(icon, { className: "w-8 h-8 text-white" })}
      </motion.div>
      
      <h3 className="text-xl font-bold text-gray-800 mb-4 tracking-wide">
        {title}
      </h3>
      
      <p className="text-gray-600 leading-relaxed">
        {description}
      </p>
    </div>
    
    <motion.div 
      className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-tr from-[#e04b5e]/10 to-transparent 
      rounded-full -ml-12 -mb-12 z-0"
    />
  </motion.div>
);

export default LandingPage;
